<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        <span>
          ข้อมูลสินค้า
        </span>
        <v-spacer></v-spacer>
        <v-btn color='primary' @click='isAdd = true'>เพิ่มสินค้า</v-btn>
      </v-card-title>
      <v-row class='px-2 pb-2'>
        <v-col cols='12' md='6' lg='4'>
          <v-text-field v-model='textSearch'
                        filled
                        @input='getProducts'
                        label='ค้นหาสินค้า'
                        dense
                        hide-details
                        placeholder='คำค้นหา'></v-text-field>
        </v-col>
        <v-col cols='12' md='6' lg='4'>
          <FilterStatus @selection='changeStatus'/>
        </v-col>
        <v-col cols='12' md='6' lg='4'>
          <v-select filled label='ร้านค้า' :items='options'
                    dense
                    hide-details
                    @change='getProducts'
                    v-model='selection'
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
          :headers='headers'
          :items='dataList'
          :items-per-page='itemsPerPage'
          :loading='loading'
          no-data-text='ไม่มีข้อมูล !'
          loading-text='กำลังโหลดข้อมูล...'
          :footer-props='footer'
          no-results-text='ไม่มีข้อมูล !'
          class='elevation-1'
      >
        <template #[`item.product_id`]='{ index }'>
          {{ index + 1 }}
        </template>
        <template #[`item.product_image`]='{ item }'>
          <v-img :src='item.product_image' class='elevation-4 rounded-lg my-1' width='50'
                 height='50'>

          </v-img>
        </template>

        <template #[`item.shop_id`]='{ item }'>
          <v-chip
              :color="item.shop_id == 1?'primary':'info'"
              :class="item.shop_id==1?`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg primary--text`:`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg info--text`"
              small
          >
            {{ item.shop_id == 1 ? `Data center` : `ร้านค้า` }}
          </v-chip>
        </template>
        <template #[`item.map_shop_product_active`]='{ item }'>
          <StatusBlock :status='String(item.map_shop_product_active)'/>
        </template>
        <template #[`item.actions`]='{ item }'>
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                  color='primary'
                  icon
                  v-bind='attrs'
                  @click='dataEdit = item;isShowDetail = true'
                  v-on='on'
              >
                <v-icon>{{ mdiTextBoxCheck }}</v-icon>
              </v-btn>
            </template>
            <span>รายละเอียด</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                  color='primary'
                  icon
                  v-bind='attrs'
                  :disabled='item.shop_id === 1'
                  @click='dataEdit = item;isEdit = true'
                  v-on='on'
              >
                <v-icon>{{ mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>แก้ไข</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                  color='primary'
                  icon
                  v-bind='attrs'
                  @click='dataEdit = item;isUpdateStatus = true'
                  v-on='on'
              >
                <v-icon>{{ item.map_shop_product_active == 1 ? mdiDeleteOutline : mdiCheck }}</v-icon>
              </v-btn>
            </template>
            <span>{{ item.map_shop_product_active == 1 ? `ระงับ` : `ปกติ` }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <AddNewProduct v-model='isAdd' @onAdd='getProducts'/>
    <EditProduct v-model='isEdit' @onUpdate='getProducts' :data-edit-product='dataEdit'/>
    <SuspendProduct v-model='isUpdateStatus' :data-update='dataEdit' @onUpdate='getProducts'/>
    <ProductDetail v-model='isShowDetail' :data-detail='dataEdit'/>
  </div>
</template>

<script>
import useProduct from './useProduct'
import FilterStatus from '@/components/FilterStatus'
import StatusBlock from '@/components/StatusBlock'
import {mdiPencilOutline, mdiDeleteOutline, mdiCheck, mdiTextBoxCheck} from '@mdi/js'
import AddNewProduct from './AddNewProduct'
import EditProduct from './EditProduct'
import SuspendProduct from './SuspendProduct'
import ProductDetail from './ProductDetail'

export default {
  props: {},
  components: {
    ProductDetail,
    SuspendProduct,
    AddNewProduct,
    FilterStatus,
    StatusBlock,
    EditProduct,
  },
  setup(_, {root, emit}) {
    return {
      ...useProduct(),
      mdiCheck,
      mdiTextBoxCheck,
      mdiPencilOutline,
      mdiDeleteOutline,
    }
  },

}
</script>

<style scoped>

</style>
