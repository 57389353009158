<template>
  <div>
    <v-dialog v-model='isShowDetail' persistent max-width='400'>
      <v-card>
        <v-card-title>
          รายละเอียดสินค้า
        </v-card-title>
        <v-divider></v-divider>
        <v-row class='mx-2 my-2'>
          <v-col cols='12'>
            <v-img :src='dataDetail.product_image' class='rounded-lg elevation-4'
                   max-height='300'></v-img>
          </v-col>
          <v-col cols='6' class='font-weight-semibold text-right'>
            รหัสสินค้า :
          </v-col>
          <v-col cols='6'>
            {{ dataDetail.product_code }}
          </v-col>
          <v-col cols='6' class='font-weight-semibold text-right'>
            ชื่อสินค้า :
          </v-col>
          <v-col cols='6'>
            {{ dataDetail.product_name }}
          </v-col>
          <v-col cols='6' class='font-weight-semibold text-right'>
            หมวดหมู่สินค้า :
          </v-col>
          <v-col cols='6'>
            {{ dataDetail.product_type_name }}
          </v-col>
          <v-col cols='6' class='font-weight-semibold text-right'>
            น้ำหนัก(หน่วย) :
          </v-col>
          <v-col cols='6'>
            {{ dataDetail.product_weight }}({{ dataDetail.product_unit }})
          </v-col>
          <v-col cols='6' class='font-weight-semibold text-right'>
            รายละเอียดสินค้า :
          </v-col>
          <v-col cols='6'>
            {{ dataDetail.product_description }}
          </v-col>

        </v-row>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color='secondary' outlined @click='$emit(`update:isShowDetail`,false )'>ปิด</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'isShowDetail',
    event: 'update:isShowDetail',
  },
  props: {
    isShowDetail: {
      type: Boolean,
      default: false,
    },
    dataDetail: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(_, { root, emit }) {

    return {}
  },

}
</script>

<style scoped>

</style>
